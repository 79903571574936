import Web3 from 'web3'
import { FixedPoolModel } from '@/models/fixed-pool-model'
import { isNumber } from 'lodash-es'
import FixedSwapContract from '@/libs/models/FixedSwapContract.js'
import FixedSwapContractBsl from '@/libs/models/FixedSwapContract.bsl.js'
import FixedSwapContractV3 from '@/libs/models/FixedSwapContract.v3.js'
import FixedSwapContractV4 from '@/libs/models/FixedSwapContract.v4.js'
import FixedSwapContractV5 from '@/libs/models/FixedSwapContract.v5.js'

const getChainConfig = (chainId: any) => {
  chainId = isNumber(+chainId) ? +chainId : chainId
  let rpc = ''
  let name = ''
  switch (chainId) {
    case 1:
    case 'eth':
      name = 'Ethereum Mainnet'
      rpc = 'https://speedy-nodes-nyc.moralis.io/1cbc67e7252c9ef1e7e63dc8/eth/mainnet'
      break
    case 3:
      name = 'Ropsten Test Network'
      rpc = 'https://speedy-nodes-nyc.moralis.io/1cbc67e7252c9ef1e7e63dc8/eth/ropsten'
      break
    case 56:
    case 'bsc':
      name = 'BSC MainNET'
      rpc = 'https://bsc-rpc.publicnode.com'
      break
    case 97:
      name = 'BSC TestNET'
      rpc = 'https://bsc-testnet.publicnode.com'
      break
  }
  return { rpc, name }
}

const getWeb3 = (chainId: any) => {
  chainId = isNumber(+chainId) ? +chainId : chainId
  const { rpc, name } = getChainConfig(chainId)
  if (rpc) return new Web3(new Web3.providers.HttpProvider(rpc))
  else return null
}
function idoContractFactory(model: FixedPoolModel) {
  const { address: contractAddress, type, chainId } = model
  const web3 = getWeb3(chainId)
  if (!contractAddress) return null
  let contract: FixedSwapContract | undefined = undefined
  switch (type) {
    case 'v1':
      contract = new FixedSwapContract({
        web3: web3,
        contractAddress,
        decimals: 18
      })
      break
    case 'v2':
      contract = new FixedSwapContractBsl({
        web3: web3,
        contractAddress,
        decimals: 18
      })
      break
    case 'v3':
      contract = new FixedSwapContractV3({
        web3: web3,
        contractAddress,
        decimals: 18
      })
      break
    case 'v4':
      contract = new FixedSwapContractV4({
        web3: web3,
        contractAddress,
        decimals: 18
      })
      break
    case 'v5':
    default:
      contract = new FixedSwapContractV5({
        web3: web3,
        contractAddress,
        decimals: 18
      })
      break
  }
  return contract
}

function etherBatchRequest(web3: Web3, methods: any[]) {
  if (!methods.length) return []
  const batch = new web3.BatchRequest()
  const tasks = Promise.all(
    methods.map(
      method =>
        new Promise((resolve, reject) => {
          batch.add(
            method.call.request({}, function(error, result) {
              if (error) {
                console.error('Errror=', method, error)
                reject(error)
              } else {
                resolve(result)
              }
            })
          )
        })
    )
  )
  batch.execute()
  return tasks
}

export const blockchainHandler = {
  getChainConfig,
  getWeb3,
  idoContractFactory,
  etherBatchRequest
}
